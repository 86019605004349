import { Alert as MuiAlert, Snackbar } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

function Alert(props: any) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Snack() {
  const dispatch = useDispatch();
  const snack = useSelector((state: any) => state.snack);

  const handleClose = (event: any, reason: any) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch({ type: 'snack-out' });
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={snack.open}
      autoHideDuration={snack.autoHideDuration}
      onClose={handleClose}
    >
      <div>
        <Alert onClose={() => dispatch({ type: 'snack-out' })} severity={snack.severity}>
          {snack.content}
        </Alert>
      </div>
    </Snackbar>
  );
}

export default Snack;
