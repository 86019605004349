import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Button } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { AnyAction } from 'redux';
import deleteKeys from '../../../actions/keys/deleteKeys';

function DeleteManyDialog({ open, setOpen, keys }: any) {
  const dispatch = useDispatch();

  return (
    <Formik
      initialValues={{}}
      onSubmit={(values, actions) => {
        dispatch(
          deleteKeys({
            ids: keys.map((e: Record<string, any>) => e.id),
            actions,
            setOpen,
          }) as any as AnyAction,
        );
      }}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Dialog open={open} onClose={() => setOpen(!open)} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Delete users</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {`Do you really want to delete users ${keys.map((e: Record<string, any>) => `${e.title}`).join(', ')}?`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(!open)} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSubmit as any} color="primary" disabled={isSubmitting}>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
}

DeleteManyDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  keys: PropTypes.array,
};

export default DeleteManyDialog;
