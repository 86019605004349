import {
  Box, Container, Grid,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import Page from '../../../components/Page';
import Profile from './Profile';
import ProfileDetails from './ProfileDetails';
import Password from './Password';
import RootUserWarning from './RootUserWarning';
import Logout from './Logout';

const useStyles = makeStyles((theme: any) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

function Account() {
  const classes = useStyles();
  const user = useSelector((state: any) => state.auth.user);

  const isRootUser = (user.roles || []).includes('root');

  return (
    <Page className={classes.root} title="Account">
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item lg={4} md={6} xs={12}>
            <Profile />
            <Box mt={3}>{isRootUser && <RootUserWarning />}</Box>
            <Box mt={3}>
              <Logout />
            </Box>
          </Grid>
          <Grid item lg={8} md={6} xs={12}>
            <ProfileDetails />
            <Box mt={3}>
              <Password />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}

export default Account;
