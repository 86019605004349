import axios from 'axios';
import Config from '../../config';

const getUsers = (text = '') => async (dispatch: any) => {
  const onSuccess = (response: any) => {
    dispatch({ type: 'users', data: response.data });
    return response;
  };

  const onError = (error: any) => {
    return error;
  };

  try {
    dispatch({ type: 'users', data: null });
    const response = await axios.post(`${Config.url}/api/users/search`, {
      text,
    });
    onSuccess(response);
  } catch (error) {
    onError(error);
  }
};

export default getUsers;
