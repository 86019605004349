import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Container,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from 'react';
import Page from '../../../components/Page';
import Toolbar from './Toolbar';
import config from '../../../config';

const useStyles = makeStyles((theme: any) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

function KeysView() {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(null);

  const handleChange = (panel: any) => (event: any, isExpanded: any) => {
    setExpanded(isExpanded ? panel : false);
  };

  const data = [
    {
      path: 'Authentication: Bearer token',
      title: 'Autenticar via chave API',
      content:
        'Generate a new API key. Use the following "Authorization" header: "Bearer API_KEY" on all POST API requests',
    },
    {
      path: 'POST /api/vault/upload',
      title: 'Carregar um novo arquivo',
      content:
        'Upload a new file. Request must be sent as Content-Type "multipart/form-data". Fields: file (the file that you are uploading), description (the description of the file), tags (tags separated by comma), auth (true if private, false if public).',
    },
    {
      path: 'POST /api/vault/sign',
      title: 'Crie uma assinatura privada',
      content:
        'Create a private file signature. Fields: shield (the shielded id of the file, returned from upload), maxAge (signature expires after maxAge milliseconds). Only to be used with private files. Also required with private files.',
    },
    {
      path: 'POST /api/vault/search',
      title: 'Procurar arquivos',
      content:
        'Search through files. Fields: text (filter by partial filename), tags (array of tags), type (filter by file type), maxDate (ISODate upload), minDate (ISODate upload), minSize, maxSize, description',
    },
    {
      path: 'GET /api/vault/:shield[?signature=SIGNATURE]',
      title: 'Ver arquivo',
      content:
        'Use /api/vault/:shield to view a public file. Use the signature generated in /api/vault/sign to view a private file.',
    },
    {
      path: 'POST /api/vault/delete',
      title: 'Excluir arquivo (lixeira ou permanente)',
      content:
        'Use /api/vault/delete to delete a file. Fields: shield (the shielded id of the file, returned from upload), permanent (boolean, whether it should go to garbage or be deleted permanently).',
    },
    {
      path: 'POST /api/vault/restore',
      title: 'Restaurar arquivo da lixeira',
      content:
        'Use /api/vault/restore to restore a file from trash. Fields: shield (the shielded id of the file, returned from upload).',
    },
  ];

  return (
    <Page className={classes.root} title="API Docs">
      <Container maxWidth={false}>
        <Toolbar />
        <Box mt={3}>
          <Box pb={1}>
            <Typography variant="h3">Welcome to Crumble!</Typography>
          </Box>
          <Box pb={3}>
            <Typography variant="body2">
              {/* eslint-disable-next-line max-len */}
              Currently, the Postman collection in the top right corner provides a much more extensive documentation of
              the API endpoints.
              <br />
              We will greatly enhance this section with future updates.
            </Typography>
          </Box>
          {config.demo && (
            <Box pb={3}>
              <Alert severity="warning">
                This is a demo and content will be reset periodically without warning.
                If you see illegal or harmful
                content here please email support@honeyside.it urgently.
              </Alert>
            </Box>
          )}
          {data.map((e) => {
            const id = e.path.split('/').join('-');
            return (
              <Accordion expanded={expanded === id} onChange={handleChange(id)} key={id}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`${id}-content`} id={`${id}-header`}>
                  <Typography className={classes.heading}>{e.path}</Typography>
                  <Typography className={classes.secondaryHeading}>{e.title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{e.content}</Typography>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </Box>
      </Container>
    </Page>
  );
}

export default KeysView;
