import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Button, TextField } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { AnyAction } from 'redux';
import createKey from '../../../actions/keys/createKey';

function CreateDialog({ open, setOpen }: any) {
  const dispatch = useDispatch();

  return (
    <Formik
      initialValues={{
        title: '',
      }}
      onSubmit={(values, actions) => {
        dispatch(
          createKey({
            title: values.title,
            actions,
            setOpen,
          }) as any as AnyAction,
        );
      }}
    >
      {({
        errors, handleBlur, handleChange, handleSubmit, handleReset, isSubmitting, touched, values,
      }) => (
        <Dialog open={open} onClose={() => setOpen(!open)} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Create new API key</DialogTitle>
          <DialogContent>
            <DialogContentText>Howdy! How do you want to reference your new key?</DialogContentText>
            <TextField
              autoFocus
              error={Boolean(touched.title && errors.title)}
              helperText={errors.title || 'Title of your API key'}
              margin="dense"
              name="title"
              onBlur={handleBlur}
              onChange={handleChange}
              label="Title"
              value={values.title}
              type="text"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                handleReset();
                setOpen(!open);
              }}
              color="primary"
            >
              Cancel
            </Button>
            <Button onClick={handleSubmit as any} color="primary" disabled={isSubmitting}>
              Create
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
}

CreateDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

export default CreateDialog;
