const initialState = {
  data: [],
};

const reducer = (state: any, action: any) => {
  if (!state) {
    state = initialState;
  }
  switch (action.type) {
    case 'updates':
      return {
        ...state,
        data: action.data,
      };
    default:
      return state;
  }
};

export default reducer;
