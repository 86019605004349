import { useEffect } from 'react';
import { Box, Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch } from 'react-redux';
import { AnyAction } from 'redux';
import Page from '../../../components/Page';
import getUsers from '../../../actions/users/getUsers';
import Results from './Results';
import Toolbar from './Toolbar';

const useStyles = makeStyles((theme: any) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

function CustomerListView() {
  const classes = useStyles();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUsers() as any as AnyAction);
  });

  return (
    <Page className={classes.root} title="Users">
      <Container maxWidth={false}>
        <Toolbar />
        <Box mt={3}>
          <Results />
        </Box>
      </Container>
    </Page>
  );
}

export default CustomerListView;
