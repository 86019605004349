import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Box, Button, Typography } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { AnyAction } from 'redux';
import deleteFiles from '../../../actions/vault/deleteFiles';
import formatBytes from '../../../utils/formatBytes';

function DeleteManyDialog({ open, setOpen, files }: any) {
  const dispatch = useDispatch();
  const [permanent, setPermanent] = useState(true);

  let size = 0;

  for (let i = 0; i < files.length; i++) {
    size += files[i].size;
  }

  if (!files[0]) {
    files = [{}];
  }

  return (
    <Formik
      initialValues={{}}
      onSubmit={(values, actions) => {
        dispatch(
          deleteFiles({
            shields: files.map((e: any) => e.shield),
            permanent: permanent || files[0].garbage,
            actions,
            setOpen,
          }) as any as AnyAction,
        );
      }}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Dialog open={open} onClose={() => setOpen(!open)} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Delete files</DialogTitle>
          <DialogContent>
            <DialogContentText>{`Do you really want to delete the selected ${files.length} files?`}</DialogContentText>
            {!files[0].garbage && (
              <Box>
                <FormControlLabel
                  control={<Switch checked={permanent} onChange={(e) => setPermanent(e.target.checked)} name="gilad" />}
                  label="Delete permanently"
                />
              </Box>
            )}
            {(permanent || files[0].garbage) && (
              <Typography variant="body2">{`${formatBytes(size)} will be freed`}</Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(!open)} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSubmit as any} color="primary" disabled={isSubmitting}>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
}

DeleteManyDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  files: PropTypes.array,
};

export default DeleteManyDialog;
