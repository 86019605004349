import setAuthToken from '../../utils/setAuthToken';

const logout = ({ navigate }: any) => (dispatch: any) => {
  dispatch({ type: 'logout' });
  setAuthToken(null);
  localStorage.removeItem('token');
  navigate('/auth/login', { replace: true });
};

export default logout;
