import axios from 'axios';
import Config from '../../config';
import capitalizeFirstLetter from '../../utils/capitlizeFirstLetter';

const createKey = ({ title, actions, setOpen }: any) => async (dispatch: any) => {
  actions.setSubmitting(true);

  const onSuccess = (response: any) => {
    const { key } = response.data;
    actions.setFieldValue('title', '');
    setOpen(false);
    dispatch({ type: 'keys-created', key });
    dispatch({ type: 'snack', content: `key ${key.title} created`, severity: 'success' });
    return response;
  };

  const onError = (error: any) => {
    if (error.response) {
      const { data } = error.response;
      if (data.title) actions.setFieldError('title', capitalizeFirstLetter(data.title));
    } else {
      dispatch({ type: 'snack', content: 'error while creating key', severity: 'error' });
    }
    return error;
  };

  try {
    const response = await axios.post(`${Config.url}/api/keys/create`, {
      title,
    });
    onSuccess(response);
  } catch (error) {
    onError(error);
  }

  actions.setSubmitting(false);
};

export default createKey;
