const initialState = {
  keys: null,
};

const reducer = (state: any, action: any) => {
  if (!state) {
    state = initialState;
  }
  let keys = [...(state.keys || [])];
  let index = 0;
  switch (action.type) {
    case 'keys':
      return {
        ...state,
        keys: (action.data || {}).keys,
      };
    case 'keys-deleted':
      keys.splice(
        keys.findIndex((e) => e.id === action.key.id),
        1,
      );
      return {
        ...state,
        keys,
      };
    case 'keys-deleted-many':
      for (let i = 0; i < action.keys.length; i++) {
        keys.splice(
          keys.findIndex((e) => e.id === action.keys[i].id),
          1,
        );
      }
      return {
        ...state,
        keys,
      };
    case 'keys-created':
      keys = [action.key, ...keys];
      return {
        ...state,
        keys,
      };
    case 'keys-edited':
      index = keys.findIndex((e) => e.id === action.key.id);
      if (index < 0) {
        return state;
      }
      keys[index] = action.key;
      return {
        ...state,
        keys,
      };
    default:
      return state;
  }
};

export default reducer;
