import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import gravatar from 'gravatar';

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    height: 100,
    width: 100,
  },
}));

function Profile({ className, ...rest }: any) {
  const classes = useStyles();
  const user = useSelector((state: any) => state.auth.user);

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardContent>
        <Box alignItems="center" display="flex" flexDirection="column">
          <Avatar className={classes.avatar} src={gravatar.url(user.email, { d: 'mp', s: '200' })} />
          <Typography color="textPrimary" gutterBottom variant="h4">
            {`${user.name}`}
          </Typography>
          <Typography color="textSecondary" variant="body1">
            {`@${user.username}`}
          </Typography>
          <Typography color="textSecondary" variant="body1" gutterBottom>
            {`${user.email}`}
          </Typography>
          <Typography color="textSecondary" variant="body1">
            Roles:
            {' '}
            {user.roles.join(', ')}
          </Typography>
        </Box>
      </CardContent>
      <Divider />
      <CardActions>
        <Button color="primary" fullWidth variant="text" href="https://gravatar.com" target="_blank">
          Mudar Foto
        </Button>
      </CardActions>
    </Card>
  );
}

Profile.propTypes = {
  className: PropTypes.string,
};

export default Profile;
