import axios from 'axios';

const getUpdates = () => async (dispatch: any) => {
  const onSuccess = (response: any) => {
    dispatch({ type: 'updates', data: response.data });
    return response;
  };

  const onError = (error: any) => {
    return error;
  };

  try {
    dispatch({ type: 'updates', data: [] });
    const response = await axios.post('https://crumble.honeyside.it/api/updates');
    onSuccess(response);
  } catch (error) {
    onError(error);
  }
};

export default getUpdates;
