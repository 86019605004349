import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box, Card, CardContent, Typography, Divider, CardActions, Button,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  root: {},
}));

function Profile({ className, ...rest }: any) {
  const classes = useStyles();

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardContent>
        <Box alignItems="center" display="flex" flexDirection="column">
          <Typography color="textPrimary" variant="h5" align="center" gutterBottom>
            You are logged in as root user.
          </Typography>
          <Typography color="textSecondary" variant="body2" align="center">
            In order to change the user details, please edit the
            {' '}
            <b>backend/.env</b>
            {' '}
            file and restart Crumble from
            command line using
            {' '}
            <b>./launcher restart</b>
            .
          </Typography>
        </Box>
      </CardContent>
      <Divider />
      <CardActions>
        <Button color="primary" fullWidth variant="text" href="https://docs.honeyside.it/crumble" target="_blank">
          View documentation
        </Button>
      </CardActions>
    </Card>
  );
}

Profile.propTypes = {
  className: PropTypes.string,
};

export default Profile;
