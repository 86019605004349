import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import changePassword from '../../../actions/auth/changePassword';

const useStyles = makeStyles({
  root: {},
});

function Password({ className, ...rest }: any) {
  const classes = useStyles();
  const user = useSelector((state: any) => state.auth.user);
  const dispatch = useDispatch();

  const isRootUser = (user.roles || []).includes('root');

  return (
    <Formik
      initialValues={{
        password: '',
        confirm: '',
      }}
      validationSchema={Yup.object().shape({
        confirm: Yup.string().oneOf([Yup.ref('password'), null], 'As senhas devem corresponder'),
      })}
      onSubmit={(values, actions) => {
        dispatch(
          changePassword({
            password: values.password,
            actions,
          }) as any as AnyAction,
        );
        actions.setValues({
          password: '',
          confirm: '',
        });
      }}
    >
      {({
        errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values,
      }) => (
        <form className={clsx(classes.root, className)} {...rest}>
          <Card>
            <CardHeader subheader="Atualize sua senha" title="Senha" />
            <Divider />
            <CardContent>
              <TextField
                error={Boolean(touched.password && errors.password)}
                fullWidth
                helperText={errors.password || 'Deve ter pelo menos 6 caracteres'}
                label="NOVA SENHA"
                margin="normal"
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                type="password"
                value={values.password}
                variant="outlined"
                disabled={isRootUser}
              />
              <TextField
                error={Boolean(touched.confirm && errors.confirm)}
                fullWidth
                helperText={errors.confirm || 'Repita sua senha'}
                label="CONFIRME SUA SENHA"
                margin="normal"
                name="confirm"
                onBlur={handleBlur}
                onChange={handleChange}
                type="password"
                value={values.confirm}
                variant="outlined"
                disabled={isRootUser}
              />
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="space-between" alignItems="center" p={2}>
              <Box>
                {isRootUser && (
                <Typography color="error" gutterBottom variant="caption">
                  O usuário root não pode ser editado aqui
                </Typography>
                )}
              </Box>
              <Button color="primary" variant="contained" disabled={isSubmitting || isRootUser} onClick={handleSubmit as any}>
                ATUALIZAR SENHA
              </Button>
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
}

Password.propTypes = {
  className: PropTypes.string,
};

export default Password;
