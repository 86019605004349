import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import NavBar from './NavBar';
import TopBar from './TopBar';

const useStyles = makeStyles((theme: any) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
  },
  top: {
    minHeight: 64,
    flex: 0,
  },
  main: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    flex: 1,
    overflow: 'hidden',
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256,
    },
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
  },
}));

function DashboardLayout() {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const token = useSelector((state: any) => state.auth.token);
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate('/auth/login', { replace: true });
    }
  }, []);

  if (!token) return null;

  return (
    <div className={classes.root}>
      <div className={classes.top}>
        <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      </div>
      <div className={classes.main}>
        <NavBar onMobileClose={() => setMobileNavOpen(false)} openMobile={isMobileNavOpen} />
        <div className={classes.wrapper}>
          <div className={classes.contentContainer}>
            <div className={classes.content}>
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardLayout;
