import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Box, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme: any) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
}));

function Toolbar({ className, ...rest }: any) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const download = () => {
    const url = '/postman-collection.zip';
    dispatch({ type: 'snack', content: 'download started: postman-collection.zip', severity: 'info' });
    const link = document.createElement('a');
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box display="flex" justifyContent="flex-end">
        <Button color="primary" variant="contained" onClick={download}>
          Download Postman Collection
        </Button>
      </Box>
    </div>
  );
}

Toolbar.propTypes = {
  className: PropTypes.string,
};

export default Toolbar;
