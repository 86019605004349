import { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Grid,
  CircularProgress,
  Button,
  Tooltip,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Edit as EditIcon, Trash as TrashIcon, Clipboard as ClipboardIcon } from 'react-feather';
import DeleteDialog from './DeleteDialog';
import EditDialog from './EditDialog';
import DeleteManyDialog from './DeleteManyDialog';

const useStyles = makeStyles((theme: any) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

function Results({ className, ...rest }: any) {
  const classes = useStyles();
  const [selectedKeyIds, setSelectedKeyIds]: Array<any> = useState([]);
  const [limit, setLimit] = useState(5);
  const [page, setPage] = useState(0);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteManyDialog, setDeleteManyDialog] = useState(false);
  const [editDialog, setEditDialog] = useState(false);
  const [selectedKey, setSelectedKey] = useState({});
  const keys = useSelector((state: any) => state.keys.keys || []);
  const dispatch = useDispatch();

  useEffect(() => {
    setSelectedKeyIds([]);
  }, [keys.length]);

  const selectedKeys = keys.filter((e: Record<string, any>) => selectedKeyIds.includes(e.id)) || [];

  const currentLength = keys.slice(page * limit, page * limit + limit).length;

  if (!keys) {
    return (
      <Grid container justifyContent="center" alignItems="center" style={{ height: '100%' }}>
        <CircularProgress />
      </Grid>
    );
  }

  if (keys.length === 0) {
    return (
      <Grid container justifyContent="center" alignItems="center" style={{ height: '100%' }}>
        <Typography variant="body1">Nenhuma chave para mostrar para os filtros selecionados.</Typography>
      </Grid>
    );
  }

  const handleSelectAll = () => {
    let newSelectedUserIds;

    if (selectedKeyIds.length !== currentLength) {
      // eslint-disable-next-line max-len
      newSelectedUserIds = keys.slice(page * limit, page * limit + limit).map((key: Record<string, any>) => key.id);
    } else {
      newSelectedUserIds = [];
    }

    setSelectedKeyIds(newSelectedUserIds);
  };

  const handleSelectOne = (event: any, id: string) => {
    if (selectedKeyIds.includes(id)) {
      const selection = [...selectedKeyIds];
      selection.splice(selection.indexOf(id), 1);
      setSelectedKeyIds(selection);
    } else {
      setSelectedKeyIds([...selectedKeyIds, id]);
    }
  };

  const handleLimitChange = (event: any) => {
    setSelectedKeyIds([]);
    setLimit(event.target.value);
  };

  const handlePageChange = (event: any, newPage: number) => {
    setSelectedKeyIds([]);
    setPage(newPage);
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table style={{ overflowX: 'scroll' }}>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedKeyIds.length === currentLength && selectedKeyIds.length > 1}
                    color="primary"
                    indeterminate={
                      selectedKeyIds.length > 0 && selectedKeyIds.length < currentLength
                    }
                    onChange={handleSelectAll}
                    disabled={currentLength === 0}
                  />
                </TableCell>
                <TableCell>Título</TableCell>
                <TableCell>Ações rápidas</TableCell>
                <TableCell>Token</TableCell>
                <TableCell>Criado</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {keys.slice(page * limit, page * limit + limit).map((key: Record<string, any>) => (
                <TableRow hover key={key.id} selected={selectedKeyIds.includes(key.id)}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedKeyIds.includes(key.id)}
                      onChange={(event: any) => handleSelectOne(event, key.id)}
                      value="true"
                    />
                  </TableCell>
                  <TableCell>
                    <Box alignItems="center" display="flex">
                      <Typography color="textPrimary" variant="body1">
                        {key.title}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell width={236}>
                    <Tooltip title="Copiar para área de transferência" arrow placement="top">
                      <Button
                        color="primary"
                        variant="contained"
                        style={{ height: 36, marginRight: 6 }}
                        onClick={async () => {
                          try {
                            await navigator.clipboard.writeText(key.token);
                            dispatch({
                              type: 'snack',
                              content: `chave ${key.title} copiado para a área de transferência`,
                              severity: 'info',
                            });
                          } catch (e) {
                            dispatch({
                              type: 'snack',
                              content: 'não foi possível copiar o token para a área de transferência',
                              severity: 'error',
                            });
                          }
                        }}
                      >
                        <ClipboardIcon size={16} />
                      </Button>
                    </Tooltip>
                    <Tooltip title="Editar" arrow placement="top">
                      <Button
                        color="primary"
                        variant="contained"
                        style={{ height: 36, marginRight: 6 }}
                        onClick={() => {
                          setSelectedKey(key);
                          setEditDialog(true);
                        }}
                      >
                        <EditIcon size={16} />
                      </Button>
                    </Tooltip>
                    <Tooltip title="Deletar" arrow placement="top">
                      <Button
                        color="primary"
                        variant="contained"
                        style={{ height: 36 }}
                        onClick={() => {
                          setSelectedKey(key);
                          setDeleteDialog(true);
                        }}
                      >
                        <TrashIcon size={16} />
                      </Button>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    {key.token.substr(0, 29)}
                    {' ... '}
                    {key.token.substr(key.token.length - 30, 29)}
                  </TableCell>
                  <TableCell>{moment(key.date).fromNow()}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <Box display="flex" flexDirection="row" alignItems="center">
        {selectedKeyIds.length > 0 && (
          <Box ml={2}>
            <Button color="primary" variant="contained" size="small" onClick={() => setDeleteManyDialog(true)}>
              Delete
              {' '}
              {`(${selectedKeyIds.length})`}
            </Button>
          </Box>
        )}
        <Box flexGrow={1}>
          <TablePagination
            component="div"
            count={keys.length}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </Box>
      </Box>
      <DeleteDialog open={deleteDialog} setOpen={setDeleteDialog} keyValue={selectedKey} />
      <DeleteManyDialog open={deleteManyDialog} setOpen={setDeleteManyDialog} keys={selectedKeys} />
      <EditDialog open={editDialog} setOpen={setEditDialog} keyValue={selectedKey} />
    </Card>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  createDialog: PropTypes.bool,
  setCreateDialog: PropTypes.func,
};

export default Results;
