import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { Box, Button, TextField } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import DialogContentText from '@mui/material/DialogContentText';
import { AnyAction } from 'redux';
import isEmpty from '../../../utils/isEmpty';
import updateFile from '../../../actions/vault/updateFile';

function EditDialog({ open, setOpen, shield = {} }: any) {
  const dispatch = useDispatch();

  return (
    <Formik
      enableReinitialize
      initialValues={{
        description: shield.description,
        tags: (shield.tags || []).join(','),
        isPrivate: shield.auth,
        region: shield.region,
      }}
      onSubmit={(values, actions) => {
        dispatch(
          updateFile({
            shield: shield.shield,
            description: values.description,
            tags: values.tags,
            isPrivate: values.isPrivate,
            actions,
            setOpen,
          }) as any as AnyAction,
        );
      }}
    >
      {({
        errors, handleBlur, handleChange, handleSubmit, handleReset, isSubmitting, touched, values,
      }) => (
        <Dialog open={open} onClose={() => setOpen(!open)} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">File details</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <b>Name:</b>
              {' '}
              {shield.name}
              <br />
              <b>Description:</b>
              {' '}
              {!isEmpty(shield.description) ? shield.description : 'n/a'}
              <br />
              <b>Type:</b>
              {' '}
              {shield.type}
              <br />
              <b>Tags:</b>
              {' '}
              {!isEmpty(shield.tags) ? shield.tags.join(', ') : 'n/a'}
              <br />
              <b>Region:</b>
              {' '}
              {shield.region || 'world'}
            </DialogContentText>
            <TextField
              error={Boolean(touched.description && errors.description)}
              helperText={typeof errors.description === 'string' ? errors.description : 'Description of the file (optional)'}
              margin="dense"
              name="description"
              label="New description"
              value={values.description}
              type="text"
              fullWidth
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <TextField
              error={Boolean(touched.tags && errors.tags)}
              helperText={typeof errors.tags === 'string' ? errors.tags : 'Tags (separated by comma)'}
              margin="dense"
              name="tags"
              label="Tags"
              value={values.tags}
              type="text"
              fullWidth
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <Box>
              <FormControlLabel
                control={<Switch checked={values.isPrivate} onChange={handleChange} name="isPrivate" />}
                label={
                  values.isPrivate ? 'Private (requires authentication)' : 'Public (accessible without authentication)'
                }
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                (window as any).size = 0;
                handleReset();
                setOpen(!open);
              }}
              color="primary"
            >
              Close
            </Button>
            <Button
              onClick={handleSubmit as any}
              color="primary"
              disabled={
                isSubmitting
                && (values.description !== shield.description || values.tags !== (shield.tags || []).join(','))
              }
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
}

EditDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  shield: PropTypes.object,
};

export default EditDialog;
