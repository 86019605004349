import { useEffect } from 'react';
import { Alert, Box, Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch } from 'react-redux';
import { AnyAction } from 'redux';
import Page from '../../../components/Page';
import getFiles from '../../../actions/vault/getFiles';
import Results from './Results';
import Toolbar from './Toolbar';
import config from '../../../config';

const useStyles = makeStyles((theme: any) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

function VaultListView() {
  const classes = useStyles();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFiles() as any as AnyAction);
  });

  return (
    <Page className={classes.root} title="Vault">
      <Container maxWidth={false}>
        <Toolbar />
        <Box mt={3}>
          <Results />
        </Box>
        {config.demo && (
          <Box pt={3}>
            <Alert severity="warning">
              Please do not upload harmful or illegal content.
              This is a demo. If you delete an existing file, please
              upload a new one with the same content type for others to see.
              In case of issues, send an email to
              support@honeyside.it.
            </Alert>
          </Box>
        )}
      </Container>
    </Page>
  );
}

export default VaultListView;
