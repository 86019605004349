import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Box, Button, Typography } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { AnyAction } from 'redux';
import formatBytes from '../../../utils/formatBytes';
import deleteFile from '../../../actions/vault/deleteFile';

function DeleteDialog({ open, setOpen, shield }: any) {
  const [permanent, setPermanent] = useState(true);
  const dispatch = useDispatch();

  return (
    <Formik
      initialValues={{}}
      onSubmit={(values, actions) => {
        dispatch(
          deleteFile({
            shield: shield.shield,
            permanent: permanent || shield.garbage,
            actions,
            setOpen,
          }) as any as AnyAction,
        );
      }}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Dialog open={open} onClose={() => setOpen(!open)} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Delete File</DialogTitle>
          <DialogContent>
            <DialogContentText>{`Delete file "${shield.name}"?`}</DialogContentText>
            {!shield.garbage && (
              <Box>
                <FormControlLabel
                  control={<Switch checked={permanent} onChange={(e) => setPermanent(e.target.checked)} name="gilad" />}
                  label="Delete permanently"
                />
              </Box>
            )}
            {(permanent || shield.garbage) && (
              <Typography variant="body2">{`${formatBytes(shield.size)} will be freed`}</Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(!open)} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSubmit as any} color="primary" disabled={isSubmitting}>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
}

DeleteDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  shield: PropTypes.object,
};

export default DeleteDialog;
