import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import {
  Alert, Box, Button, Grid, TextField, Typography,
} from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { AnyAction } from 'redux';
import formatBytes from '../../../utils/formatBytes';
import uploadFiles from '../../../actions/vault/uploadFiles';
import config from '../../../config';

function UploadDialog({ open, setOpen }: any) {
  const dispatch = useDispatch();
  const [size, setSize] = useState(0);

  return (
    <Formik
      initialValues={{
        files: [],
        description: '',
        tags: '',
        isPrivate: false,
        region: '',
      }}
      onSubmit={(values, actions) => {
        dispatch(
          uploadFiles({
            files: values.files,
            description: values.description,
            tags: values.tags,
            isPrivate: values.isPrivate,
            region: values.region,
            actions,
            setOpen,
          }) as any as AnyAction,
        );
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        handleReset,
        isSubmitting,
        touched,
        setFieldValue,
        values,
      }) => (
        <Dialog open={open} onClose={() => setOpen(!open)} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Enviar arquivos</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Selecione um ou vários arquivos para fazer upload. ATENÇÃO!!! Tamanho máximo de arquivo permitido: 100mb.
              A mesma descrição e tags serão aplicadas a todos os arquivos
              selecionados, mas eles podem ser editados posteriormente.
            </DialogContentText>
            <Grid container alignItems="center">
              <Button color="primary" variant="contained" component="label">
                Enviar arquivo
                <input
                  type="file"
                  name="files"
                  onChange={(e: any) => {
                    (window as any).size = 0;
                    for (let i = 0; i < e.target.files.length; i++) {
                      (window as any).size += e.target.files[i].size;
                    }
                    setFieldValue('files', e.target.files);
                    setSize((window as any).size);
                  }}
                  multiple
                  hidden
                />
              </Button>
              <Box ml={3}>
                <Typography variant="body1">
                  {values.files
                    ? `${values.files.length} file${values.files.length > 1 ? 's' : ''} selected, ${formatBytes(
                      (window as any).size || 0,
                    )}`
                    : 'Nenhum arquivo selecionado'}
                </Typography>
              </Box>
            </Grid>
            <TextField
              error={Boolean(touched.description && errors.description)}
              helperText={errors.description || 'Descrição dos arquivos (opcional)'}
              margin="dense"
              name="description"
              label="Descrição"
              value={values.description}
              type="text"
              fullWidth
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <TextField
              error={Boolean(touched.tags && errors.tags)}
              helperText={errors.tags || 'Tags (separados por vírgula)'}
              margin="dense"
              name="tags"
              label="Tags"
              value={values.tags}
              type="text"
              fullWidth
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <TextField
              error={Boolean(touched.region && errors.region)}
              helperText={errors.region || 'Região (padrão: "mundo")'}
              margin="dense"
              name="region"
              label="Região"
              value={values.region}
              type="text"
              fullWidth
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <Box>
              <FormControlLabel
                control={<Switch checked={values.isPrivate} onChange={handleChange} name="isPrivate" />}
                label={
                  values.isPrivate ? 'Privado (requer autenticação)' : 'Público (acessível sem autenticação)'
                }
              />
            </Box>
            {config.demo && (
              <Box pt={1}>
                <Alert severity="warning">Arquivos grandes são desabilitados no modo de demonstração. O tamanho máximo do arquivo é 30 MB.</Alert>
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                (window as any).size = 0;
                setSize((window as any).size);
                handleReset();
                setOpen(!open);
              }}
              color="primary"
            >
              Cancelar
            </Button>
            <Button
              onClick={handleSubmit as any}
              color="primary"
              disabled={
                isSubmitting || !values.files
                || values.files.length === 0 || (config.demo && size > 1024 * 1024 * 30)
              }
            >
              Enviar
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
}

UploadDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

export default UploadDialog;
